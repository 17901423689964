<template>
  <el-dialog
    v-if='visible'
    title="文件列表"
    :close-on-click-modal="false" 
    @close='closeEvent'
    :visible.sync="visible"  width="35%">
    
    <div class="list">
        <el-button size="small" v-if="viewer.depth > 0" @click="back">返回上一级</el-button>
        <el-table
            :data="viewer.showFiles"
            style="width: 100%"
            class="table-file"
            height="400"
            row-class-name="document-item">
            <el-table-column
            prop="name"
            label="文件名称">
            <template slot-scope="scope">
                <div class="file-column-name" @click="render(scope.row)">
                <span style="cursor: pointer; line-height: 26px;">
                    <i class="el-icon-folder-opened" v-if="scope.row.type == 0" style="font-size: 16px; margin-right: 5px;"></i> 
                    <i class="el-icon-document" v-if="scope.row.type == 1" style="font-size: 16px; margin-right: 5px;"></i> 
                    {{scope.row.name}}
                </span>
                </div>
            </template>
            </el-table-column>
            <el-table-column
            label="类型"
            width="120">
            <template slot-scope="scope">
                <span>{{scope.row.type|fileType(scope.row)}}</span>
            </template>
            </el-table-column>
            <el-table-column
            label="操作"
            width="140">
            <template slot-scope="scope">
                <el-button
                v-if="scope.row.type == 1"
                size="mini"
                type="primary"
                @click="render(scope.row)">阅读</el-button>
                <el-button
                v-if="scope.row.type == 1"
                size="mini"
                type="primary"
                @click="handleToFile(scope.row)">保存</el-button>
            </template>
            </el-table-column>
        </el-table> 
    </div>

    <span slot="footer">
      <div class="btn">
          
          <el-button size="small" @click="visible = false">取消</el-button>
          <el-button type="primary" size="small" @click="closeEvent">确定</el-button>
      </div>
    </span>

  </el-dialog>
</template>
<script>
export default {
    data(){
        return{
            visible:false,
            viewerKey: 'shareDocList', // 获取视图数据
            documentType:''
        }
    },
    computed:{
        shareInfo() {
            return this.$store.state.auth.editShare
        },
        viewer() {
            return this.$store.state.doc.viewer[this.viewerKey] || {}
        }
    },
    methods:{
        // 初始化数据
        show(){
            this.$store.commit('doc/createViewer', {
                viewerKey: this.viewerKey,
                defaultFiles: this.shareInfo.resources
            })
        },
        // 归档
        handleToFile(row){
            let rootId = this.documentType != '' ?  this.documentType:row.documentId;
            let documentId = row.documentId || row.id
            this.$emit('toSaveMyDocument',documentId,this.shareInfo.id,rootId)
        },

        // 关闭弹框数据重置
        closeEvent(){
            this.visible = false;
        },


        back(){
            this.$store.dispatch('doc/backFolder', {
                vkey: this.viewerKey
            })
        },
        // 跳转
        render(docInfo){
            docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
            if(docInfo.type == 0){
                if(this.documentType=='' && this.viewer.depth == 0){
                    this.documentType = docInfo.documentId
                } 
                this.$store.dispatch("doc/loadChildrenByShare", {
                    vkey: this.viewerKey,
                    folder: docInfo
                })
            }else{
                this.$ebus.$emit('publicReader',{
                    shareId:this.shareInfo.id, 
                    id:docInfo.documentId,
                    documentId: this.documentType||docInfo.documentId,
                    name:'',
                    suffix:'ofd'
                })
            }

        },
        
      
       

    },
}
</script>
<style lang='less' scoped>
/deep/ .el-dialog__body {
    padding: 10px 20px 15px 20px !important;
}
.list{
    .el-button{
        margin-bottom: 10px;
    }
}

</style>
