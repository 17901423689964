<template>
  <div>
    <!-- <el-table :data="defaultList" :show-header="false" style="width: 100%">
      <el-table-column label="商品名称" prop="name" min-width="600px">
        <template slot-scope="scope">
          <div class="share-title">
            <div @click="read(scope.row)"> {{ scope.row.name }}</div>
            <div class="share-box">共享：{{scope.row.depth}}次 | 阅读：{{scope.row.readCount}}次</div>
          </div>
          <div> -->
            <!-- <el-button type="text" size="mini" @click="sql(scope.row)">授权链</el-button> -->
           <!-- <el-button type="text" size="mini"  @click="showQR(scope.row.urlShort)">查看二维码</el-button>
            <el-button type="text" size="mini"  @click="copyUrl(scope.row.urlShort)">复制共享链接</el-button> -->
            <!-- <el-button type="text" size="mini">授权管理</el-button>
            <el-button type="text" size="mini">删除</el-button> -->
          <!-- </div>
        </template>
      </el-table-column>
    </el-table> -->
      <el-table
          v-if="favoriteTabNum == 1 && defaultList.length != 0"
          :data="defaultList"
          size="small"
          height="calc(100vh - 265px)"
          @row-click="tableRowClickEvent"
          style="width: 100%;">
          <!-- <el-table-column
            type="selection"
            align="center"
            width="50">
            </el-table-column> -->
          <el-table-column
            label="共享标题"
            width="300">
            <template slot-scope="scope">
                <div class="file-box">
                  <img src="@/assets/img/share.png" alt="scope.row.name" lazy>
                  <span>{{scope.row.name}}</span>
                </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ownerName"
            label="共享人"
            align="center">
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="共享日期"
            align="center">
            <template slot-scope="scope">
              {{scope.row.createDate | date}}
            </template>
          </el-table-column>
          <el-table-column
            prop="endTime"
            label="剩余时间"
            align="center">
             <template slot-scope="scope">
              {{ checkEndTile(scope.row.startTime,scope.row.endTime,scope.row.state) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            align="center">
              <template slot-scope="scope">
               <el-tag v-if='checkEndTile(scope.row.startTime,scope.row.endTime,scope.row.state)  != "0天" && checkEndTile(scope.row.startTime,scope.row.endTime)  != "未开始"' type="success" effect="dark" size='small'>正常状态</el-tag>
               <el-tag v-else type="danger" effect="dark" size='small'>不可阅读</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="300"
            align="center">
            <template slot-scope="scope">
                <el-button v-if='scope.row.canArchive && scope.row.state != 3' type="text" size="small" @click.stop="handleToFile(scope.row)">保存</el-button>
                <el-button type="text" size="small" @click.stop="showTag(scope.row)">标签管理</el-button>
                <el-button v-if='checkEndTile(scope.row.startTime,scope.row.endTime,scope.row.state)  == "0天" ' type="text" size="small"  @click.stop="applyRead(scope.row)">申请阅读</el-button>
                <el-button type="text" size="small"  @click.stop="showQR(scope.row.urlShort)">二维码共享</el-button>
                <el-button type="text" size="small"  @click.stop="copyUrl(scope.row.urlShort)">链接共享</el-button>
                <el-button type="text" size="small"  @click.stop="tableRowClickEvent(scope.row,'all')">详情</el-button>
            </template>
          </el-table-column>
      </el-table>

      <el-row :gutter="20" v-if="favoriteTabNum == 2 && defaultList.length != 0">
        <template v-if="defaultList.length > 0">
           <el-col :span="5" style="margin-bottom: 15px;" v-for="(defaultItem, i) in defaultList" :key="i">   
              <el-card class="shareme-card" :body-style="{ padding: '0px' }" @click.native="tableRowClickEvent(defaultItem)">
                  <div class="share-card-top">
                      <!-- 图片展示 -->
                      <img src="@/assets/file.png" alt="defaultItem.name" lazy>
                  </div>
                  <div class="share-card-middle">
                      <span style="margin-bottom:10px" :title="defaultItem.name">{{defaultItem.name}}</span>
                      <div style="font-size:14px;color:#666">
                          <span>
                              共享人：
                              {{defaultItem.ownerName}}
                          </span>
                      </div>
                      <div style="font-size:12px;color:#999">
                          <span>{{defaultItem.createDate | date}}</span>
                      </div>
                      <div style="margin-top:15px">
                        <el-button v-if='checkEndTile(scope.row.startTime,scope.row.endTime,scope.row.state)  == "0天"' type="text" size="small"  @click.stop="applyRead(defaultItem)">申请阅读</el-button>
                        <el-button type="text" size="small" @click.stop="showTag(defaultItem)">标签管理</el-button>
                        <el-button type="text" size="small" @click.stop="showQR(defaultItem.urlShort)">二维码共享</el-button>
                        <el-button type="text" size="small" @click.stop="copyUrl(defaultItem.urlShort)">链接共享</el-button>
                        <el-button type="text" size="small"  @click.stop="tableRowClickEvent(defaultItem,'all')">详情</el-button>
                      </div>
                  </div>
              </el-card>
          </el-col>
        </template>
        
      </el-row>
      <div class="no-data-box" v-if='defaultList.length == 0'>
          <img src="@/assets/no-file.svg" alt="">
          <span>暂无数据</span>
      </div>
    <el-pagination
      class="pagebox"
      v-if="defaultList.length > 0"
      @current-change="pageChanged"
      :page-size="query.pageSize"
      layout="total,  prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <FileChain ref="chain" />
    <ShareMeTag ref="ShareMeTag" />
    <el-dialog :append-to-body='true'
    :close-on-click-modal="false" 
      title="共享二维码"
      :visible.sync="showQRCode"
      width="350px">
      <div style="text-align: center;" class='qrCode-style'>
        <qr :margin="10" :text="shareUrl" :size="200"></qr>
        <p>请扫描二维码查看共享信息</p> 
      </div>
    </el-dialog>

    <ShareDialog ref="shareDialog"></ShareDialog>

      <!-- 选择我的文档 -->
      <SelectMyDocument ref='SelectMyDocument' @SelectMyDocument='SelectMyDocument'></SelectMyDocument>

      <!-- 弹出文件列表 -->
      <ReaderFileList ref='ReaderFileList' @toSaveMyDocument='toSaveMyDocument'></ReaderFileList>
  </div>
</template>

<script>
import qr from 'vue-qr'
import { documentAPI } from 'liankong-ui-api' 
import ShareMeTag from './AddTag.vue'
import FileChain from '../files/FileChain.vue'
import ShareDialog from '../liankong/components/ShareDialog.vue'
import SelectMyDocument from '../onlineEditing/components/SelectMyDocument.vue'
import ReaderFileList from './readerFileList.vue'
export default {
  componentName: "MyDocument",
  data() {
    return {
       showQRCode: false,
      shareUrl: null,
      query: {
        name: '',
        pageSize: 10
      },
      favoriteTabNum: 1,
      currentDocument:{
        documentId:null,
        documentRootId:null,
        shareId:null,
        userDocumentFolderId:null
      }
    };
  },
  components: {
    FileChain,
    qr,
    ShareDialog,
    ShareMeTag,
    SelectMyDocument,
    ReaderFileList
  },

  computed: {
    defaultList(){
      return this.$store.state.share.views.receiver.list || []
    },  
    total() {
      return this.$store.state.share.views.receiver.paging.total
    }
  },
  watch: {},
  methods: {
    toSaveMyDocument(documentId,shareId,parentId){
      this.currentDocument = {
        documentId:documentId,
        documentRootId:parentId,
        shareId:shareId,
        userDocumentFolderId:null
      }
      this.$refs.SelectMyDocument.show('folder',documentId,'shareToMe');
    },
    // 保存到我的文档
    SelectMyDocument(parentId){
      if(parentId != null){
        this.currentDocument.userDocumentFolderId = parentId;
        documentAPI.shareArchiveDocument(this.currentDocument).then(res => {
          if(res.code == 0){
            this.$message.success('保存成功，请到我的文档中查看!')
          }else{
            this.$message.error(res.message)
          }
        })
      }
    },
    // 归档
    handleToFile(row){
      this.$store.dispatch("auth/loadShare", row.id).then(data => {
        let { resources } = this.$store.state.auth.editShare;
        if(resources.length == 1 && resources[0].type == 1){
          this.currentDocument = {
            documentId:resources[0].documentId,
            documentRootId:resources[0].documentId,
            shareId:row.id,
            userDocumentFolderId:null
          }
          this.$refs.SelectMyDocument.show('folder',resources[0].documentId,'shareToMe');
        }else{
          this.$refs.ReaderFileList.visible = true;
          this.$nextTick(()=>{
            this.$refs.ReaderFileList.show()
          })
        }
      })
    },
    checkEndTile(startTime,endTime,state){
      if(state == 3){
        return '0天'
      }
      let currentTime = new Date();
      if(startTime && endTime){
        // 有开始时间和结束时间
        let start = new Date(startTime);
        let end = new Date(endTime);
        if(currentTime.getTime() <  start.getTime()){
          return '未开始'
        }else {
          if(currentTime.getTime() <  end.getTime()){
            return this.timestampToTime(end.getTime() - currentTime.getTime() )
          }else{
            return '0天'
          }
        }
      }else if(!startTime && !endTime){
        // 都没有
        return '永久'
      }else if(!startTime && endTime){
        // 没有开始时间，有结束时间
        let end = new Date(endTime);
        // 如果当前时间小于结束时间则显示剩余时间，如果大于或者等于显示0天
        if(currentTime.getTime() <  end.getTime()){
          return this.timestampToTime(end.getTime() - currentTime.getTime() )
        }else{
          return '0天'
        }
        
      }else if(startTime && !endTime){
        // 有开始时间 ，没有结束时间
        let start = new Date(startTime);
        // 如果当前时间小于开始时间显示未开始，否则显示永久
        if(currentTime.getTime() <  start.getTime()){
          return '未开始'
        }else{
          return '永久'
        }
      }
    },
    timestampToTime(timestamp) {
      var s = parseInt(timestamp / 1000 % 60)//秒 余下多少秒（0-59）
      var m = parseInt(timestamp / 1000 / 60 % 60); //余下了多少分钟（0-59）
      var h = parseInt(timestamp / 1000 / 60 / 60 % 24);//余下多少时（0-23）

      //获取多少天
      var t = parseInt(timestamp / 1000 / 60 / 60 / 24);
      return t + "天" + h + "小时" + m + "分钟" + s + "秒";
    },
    tableRowClickEvent(row,type) {
      if( type == 'all'){
        this.$refs.shareDialog.dialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.shareDialog.onLoadInit(row,true);
        })
      }else{
         this.$store.dispatch("auth/loadShare", row.id).then(data => {
            let { resources } = this.$store.state.auth.editShare;
            if(resources.length == 1){
              if(resources[0].type == 1){
                this.$nextTick(()=>{
                  if( this.$refs.shareDialog){
                    this.$refs.shareDialog.render(resources[0]);
                  }
                })
              }else{
                this.$refs.shareDialog.dialogVisible = true;
                this.$nextTick(()=>{
                  this.$refs.shareDialog.onLoadInit(row,true);
                })
              }
            }else{
              this.$refs.shareDialog.dialogVisible = true;
              this.$nextTick(()=>{
                this.$refs.shareDialog.onLoadInit(row,true);
              })
            }  
          })
      }
         
        
    },
    applyRead(row){
      this.$store.commit("cart/resetRequest")
       this.$store.dispatch("auth/loadShare", row.id).then(data => {
          let { resources } = this.$store.state.auth.editShare;
          resources.map(item =>{
            item.id = item.documentId;
            this.$store.commit("cart/push", {
              viewerKey: 'request',
              doc: item,
            })
          })
          this.$store.commit('setCartShow', true)
        })
    },
    // 阅读
    read(item){
       let queryId = {
        id: item.id,
      };
      this.$router.push({
        // path: "shares/detail",
        path: '/file/detail',
        query: queryId,
      });
    },
    //复制共享链接
    copyUrl(id){
      this.$copyText(this.$options.filters["shareUrl"](id)).then(e => {
        this.$message({
          message: '复制成功,快去共享给好友吧',
          type: 'success'
        })
      }, e => {
        this.$message({
          message: '复制失败，请手动复制分析连接',
          type: 'error'
        })
      })
    },
    // 显示二维码
    showQR(showQR){
      this.shareUrl = this.$options.filters["shareUrl"](showQR)
      this.showQRCode = true
    },
    //显示标签管理
    showTag(row){
      this.$refs['ShareMeTag'].disableHidden = true
       this.$nextTick(()=>{
                this.$refs['ShareMeTag'].init(row);
            })
    },
    //进入指定目录
    handleEnterFolder(folder) {
      if (folder) {
        if (folder.type == 0) {
          this.$store.dispatch("document/enterFolder", folder);
        }
      } else {
        this.$store.dispatch("document/enterFolder");
      }
    },
    pageChanged(num) {
        this.getList(num)
    },
    getList(num) {
      this.$store.dispatch('share/toMyList',{
        reset:true,
        query: {
          name: this.query.name,
          pageNum: num,
          pageSize: this.query.pageSize
        }
      })
    }

  },
  created() {
    this.getList(1)
    this.$store.state.cartForm.viewerKey = 'request'
  },
  mounted() {
    this.$ebus.$on('favoriteSearch', fileName=>{
        this.query.name = fileName
        this.getList(1)
    })
    this.$ebus.$on('clearSearch', vals=>{
        this.query.name = ''
        this.getList(1)
    })
    this.$ebus.$on('favoriteTabEvent', favoriteTabNum=>{
        this.favoriteTabNum = favoriteTabNum;
    })
  },
  beforeDestroy() {
      this.$ebus.$off('favoriteSearch');
      this.$ebus.$off('favoriteTabEvent');
      this.$ebus.$off('clearSearch');
  }
};
</script>
<style lang='less' scoped>
@import "../../styles/index";
.el-main-width {
  padding-top: 0px;
}
.addbox {
  position: relative;
}
.addbox i {
  position: absolute;
  right: 0;
  bottom: -31px;
  font-size: 20px;
  z-index: 55;
}
.document {
  // height: 400px;
  // background: red;
  text-align: center;
  // border: 1px solid @Brand;
  margin-bottom: 20px;
}
.document-top {
  position: relative;
}
.document-top img {
  width: 100%;
  height: auto;
}

.document-middle {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: @h6;
  padding: 10px 20px;
}
.document-bottom {
  padding-bottom: 30px;
}
.title {
  text-align: left;
  font-size: @h5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.info-tag {
  margin-top: 5px;
}

.red_share {
  margin-top: 10px;
  color: @Info;
}
.more {
  top: 10px;
  right: 10px;
  position: absolute;
}
.more-menu {
  font-size: 26px;
  color: @Brand;
}

.el-form-item {
  margin-bottom: 0px;
}
.share-title::before{
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 5px;
  background: #169bd5;
  top:5px;
  left: 0;
}.share-title{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color:#169BD5;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}
// .el-button--text{
//   color:#666;
//   font-size: 12px;
// }
.share-box{
  color: #666;
  font-size: 12px;
}
.el-row {
  height:calc(100vh - 265px);
  overflow: auto;
  .el-col-5 {
    width: 19.83%;
  }
}
/deep/ .el-table {
  .description{
    .cell{  
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
  }
  .file-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 10px;
            vertical-align: middle;
            margin-bottom: 3px;
        }
        span {
            display: block;
            width: 240px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
    }
  .el-table__header-wrapper {
    .has-gutter {
      tr th{
        border:none;
        background: #F2F2F2;
        color: #666;
      }
    }
  }
  .el-table__body-wrapper {
    tr td {
      border:none;
      padding: 12px 0;
    }
  }
  &::before {
    height: 0px;
  }
}
.pagebox {
  margin-top: 20px;
  text-align: left;
}
.shareme-card {
    height: 240px;
    padding: 10px;
    box-sizing: border-box;
    .share-card-top {
        height: 110px;
        line-height: 110px;
        text-align: center;
        img{
          margin-top: 10px;
        }
    }
    .share-card-middle {
        margin: 10px;
        >span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 5px 0 0 0;
            .el-button.el-button--text.el-button--small {
              padding: 0;
            }
        }
    }
}
.no-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0%;
    margin-right: 10%;
    img {
        width: 380px;
        height: 280px;
    }
    span {
        color: #bdc1cd;
        font-size: 16px;
        margin-top: 15px;
        font-weight: 100px;
    }
}
</style>